// @flow strict
import styled from 'styled-components'
import React from 'react'
import { graphql } from 'gatsby'
import type { MarkdownResult, Location } from '../types'
import Layout from '../components/layout'
import Column from '../components/column'
import Paragraph from '../components/paragraph'
import StyledLink from '../components/link'

type Props = {
  data: MarkdownResult & {},
  location: Location,
}

const ListItem = styled.li`
  text-decoration: none;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
`

const pluralize = (
  num: number,
  singular: string,
  plural: string = `${singular}s`
) => (num > 1 ? `${num} ${plural}` : `${num} ${singular}`)

const ArticlesList = ({ data, ...props }: Props) => (
  <Layout {...props}>
    <Column>
      <h1>All Articles</h1>
      <h4>{pluralize(data.allMarkdownRemark.totalCount, 'article')}</h4>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <ListItem key={node.id}>
          <StyledLink to={node.fields.slug}>
            <h3 style={{ width: '100%' }}>
              {node.frontmatter.title}
              <span style={{ color: '#bbb' }}> -{node.frontmatter.date}</span>
            </h3>
            <Paragraph>{node.excerpt}</Paragraph>
          </StyledLink>
        </ListItem>
      ))}
    </Column>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ArticlesList
